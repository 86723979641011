var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: "vue-select",
                      label: _vm.$t("Loja"),
                      placeholder: _vm.$t("Todas"),
                      options: _vm.storeOptions,
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "sku",
                      required: false,
                      "min-search-length": 3,
                    },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockItems",
            staticClass: "mb-1 bordered",
            attrs: {
              "show-empty": "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_POSITION.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.stockItems,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(skuName)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-product-router-link", {
                      attrs: {
                        "sku-id": item.sku.id,
                        "product-id": item.sku.productId,
                        "product-name": item.sku.name,
                        ean: item.sku.ean,
                        "show-stock-position-link": false,
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(stock)",
                fn: function (row) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "hyperlink",
                        attrs: {
                          to: {
                            name: "stock-movement-list",
                            query: {
                              storeId: row.item.store.id,
                              skuId: row.item.sku.id,
                            },
                          },
                          title: _vm.$t("Visualizar movimentações"),
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ExternalLinkIcon" },
                        }),
                        _vm._v(" " + _vm._s(row.item.stock) + " "),
                      ],
                      1
                    ),
                    row.item.waitForReclaim != null
                      ? _c("e-router-link", {
                          attrs: {
                            id: "reclaim_product-" + row.item.sku.id,
                            header: _vm.$t("Comodato em aberto"),
                            links: [
                              {
                                title: _vm.$t("Retorno de produtos"),
                                to: {
                                  name: "reclaim-list",
                                  query: {
                                    storeId: row.item.store.id,
                                    skuId: row.item.sku.id,
                                    startDate: null,
                                    endDate: null,
                                  },
                                },
                              },
                            ],
                            value: "(" + row.item.waitForReclaim + ")",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(reservation)",
                fn: function (row) {
                  return [
                    row.item.reservation
                      ? _c("e-router-link", {
                          attrs: {
                            to: {
                              name: "stock-reservation-list",
                              query: {
                                storeId: row.item.store.id,
                                skuId: row.item.sku.id,
                              },
                            },
                            title: _vm.$t("Visualizar reservas"),
                            value: row.item.reservation,
                          },
                        })
                      : _c("span", [_vm._v("0")]),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }